@import '~assets/less/mobile-colors';

.mat-mdc-form-field-type-mat-native-select .mdc-text-field__input {
    position: relative;
    z-index: 10;
}

.c24-material-bottom-sheet-panel {
    padding-bottom: env(safe-area-inset-bottom);

    .mat-bottom-sheet-container {
        padding-bottom: 27px !important;
    }
}

.keyboard-open .c24-material-bottom-sheet-panel {
    padding-bottom: 0;
}

.mat-calendar-body-cell-content::before {
    margin: 0 !important;
}
