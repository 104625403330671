@import '~assets/less/mobile-colors';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@angular/cdk/a11y-prebuilt.css';
@import '~node_modules/@vv-ham/ng-material-styles/c24-ng-mobile-material-theme.css';

@import './assets/styles/material';

html {
    scroll-behavior: smooth;
}

body {
    display: block;
    margin: 0;
    padding: 0;
    background: @background-light-grey;
    font-family: Arial, sans-serif;
    color: @text-dark;

    &:has(
            #loading-skeleton-container,
            #termination-request-error-modal,
            #termination-request-success-modal,
            waiting-modal
        ),
    &:has(modal-backdrop:not([hidden]) ui-shared-waiting-modal) {
        overflow: hidden;
    }
}

// we need to override certain styles delivered from common.styles (which is needed for the wireframe)
.ui-page-theme-c24 header {
    background-color: transparent;
    font-size: 14px;
}

body.prevent-background-scroll {
    overflow: hidden;
}

.sso-page {
    height: 100%;
}
